@import "../src/components/billets/styles/index.css";

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
   url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: regular;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home_container{
  font-size: 10px;
  display: block;
  width: 100%;
  height: auto;
}

.login_box {
  display: flex;
   justify-content: center; 
   background-color:#161414; 
   padding:20px;
   box-shadow: 10px 10px 10px 0 #000;
}

.login_button {
  width: 100px;
  height: 30px;
  padding: 5px;
  margin: 10px 0px;
  border: 2px solid #3498db;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
  background-color: #191919;
  outline: none;
  text-align:center;
  transition: 0.3s;
}
.login_button:hover {
  cursor: pointer;
  box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.5);
}
.input_username {
  width: 200px;
  height: 20px;
  padding: 5px;
  margin: 10px 0px;
  border: 2px solid #3498db;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 600;
  background-color: #191919;
  outline: none;
  text-align:center;
  transition: 0.3s;
}
.input_username:focus {
  box-shadow: 0 0 20px 0px rgba(255, 255, 255, 0.5);
}

.dashboardArticleTitle {
  text-align: left;
}
.dashboardArticleTitle :hover {
  text-decoration: underline;
}
.scorecardsWrapper {
  display: flex;
  margin: 0;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
}
.scorecard {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  border: 1px solid #3498db;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  position: relative;
}
.scorecard p {
  text-align: left;
  color: #ffffff;
  margin: 5px;
}
.cardButtons {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
.advertisementWrapper{
  display: flex;
  margin: 0;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  position: relative;
}
.advertisementUnit{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  border: 1px solid #3498db;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  position: relative;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 140px;
  max-width: auto;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  font-size: 14px;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.advertTime {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  border: 1px solid #3498db;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  position: relative;
}
.advertTime p {
  text-align: left;
  color: #ffffff;
  margin: 5px;
  font-size: 14px;
}
blockquote.twitter-tweet {
  display: inline-block;
  font-family: "Helvetica Neue", Roboto, "Segoe UI", Calibri, sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  border-color: #eee #ddd #bbb;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
  margin: 10px 5px;
  padding: 0 16px 16px 16px;
  max-width: 468px;
}

blockquote.twitter-tweet p {
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
}

blockquote.twitter-tweet a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
  outline: 0 none;
}

blockquote.twitter-tweet a:hover,
blockquote.twitter-tweet a:focus {
  text-decoration: underline;
}
