@media print {
  *,
  :after,
  :before {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }
}


.text-center {
  text-align: center;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:after,
.container:before {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 960px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:after,
.row:before {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-sm-1,
.col-sm-3,
.col-sm-4,
.col-sm-8,
.col-sm-9,
.col-sm-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-7,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-7,
.col-xs-12 {
  float: left;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-12 {
  width: 100%;
}

@media (min-width: 960px) {
  .col-sm-1,
  .col-sm-3,
  .col-sm-4,
  .col-sm-8,
  .col-sm-9,
  .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-12 {
    width: 100%;
  }
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.pull-left {
  float: left !important;
}

.article-container.article-view-container article {
  padding: 0 28px 0 60px;
}

.article-container.article-view-container article.view-article .categories {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #0d47a1;
  margin-top: 10px;
  text-transform: capitalize;
  text-align: left;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  box-sizing: border-box;
}

.article-container.article-view-container article.view-article .categories a {
  position: relative;
  padding: 5px;
  top: -1px;
}

.article-container.article-view-container
  article.view-article
  .categories
  .categories-seperator {
  display: inline-block;
  color: #999;
  top: 5px;
  position: relative;
}

.article-container.article-view-container article.view-article .imagecredit {
  text-transform: capitalize;
  text-align: right;
  font-size: 19px;
  font-size: 1.2rem;
}

.article-container.article-view-container article.view-article header .title {
  font-family: Montserrat, sans-serif;
  font-size: 68px;
  font-size: 2.15rem;
  font-weight: bolder;
  margin-bottom: 40px;
}

.article-container.article-view-container
  article.view-article
  header
  .author-bar {
  display: inline-block;
  background: #fff;
  font-size: 24px;
  font-size: 1.5rem;
  left: -18px;
  margin-top: -13px;
  margin-bottom: 9pt;
  max-height: 60px;
  padding: 7px 19px;
  position: relative;
  vertical-align: top;
  width: 100%;
}

.article-container.article-view-container
  article.view-article
  header
  .author-bar
  .circle-pic {
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 50%, 0.49);
  height: 45px;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 45px;
  z-index: 1;
}

.article-container.article-view-container
  article.view-article
  header
  .author-bar
  .circle-pic
  img {
  max-width: 100%;
}

.article-container.article-view-container
  article.view-article
  header
  .author-bar
  .author {
  display: inline-block;
  background: #fff;
  font-size: 24px;
  font-size: 0.75rem;
  left: -14px;
  margin-top: 0;
  padding-left: 1pc;
  position: relative;
}

.article-container.article-view-container
  article.view-article
  header
  .author-bar
  .publish-date {
  font-size: 1pc;
  font-size: 0.5rem;
  padding-top: 10px;
  position: relative;
  right: -2.6rem;
  top: -1.5rem;
}

.article-container.article-view-container article.view-article .summary {
  border-top: 1px solid hsla(0, 0%, 77%, 0.51);
  color: #000;
  color: rgba(0, 0, 0, 0.65);
  font-size: 2pc;
  font-size: 1rem;
  font-weight: 400;
  line-height: 40px;
  line-height: 1.25rem;
  margin-bottom: 30px;
  margin-top: 0;
  padding-top: 15px;
}

.article-container.article-view-container article.view-article .content {
  font-family: Georgia;
  font-size: 27px;
  font-size: 0.85rem;
  color: #333;
  line-height: 20px;
  line-height: 1.25rem;
}

.article-container.article-view-container article.view-article .content p {
  margin: 0 0 20px;
}

.article-container.article-view-container article.view-article .article-tags {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 10px 0;
  position: relative;
  width: 100%;
  cursor: pointer;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.article-container.article-view-container
  article.view-article
  .article-tags
  .tag {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 5px 40px 5px 0;
  position: relative;
  float: left;
  font-size: 22px;
  font-size: 0.7rem;
  border: 2px solid #194586;
  border-radius: 5px;
  overflow: hidden;
  width: 90%;
  text-transform: capitalize;
}

.article-container.article-view-container
  article.view-article
  .article-tags
  .tag
  .desc {
  margin: auto;
  color: #194586;
  text-decoration: none;
  padding: 5px;
}

.article-container.article-view-container
  article.view-article
  .article-tags
  .tag:hover {
  background: #194586;
}

.article-container.article-view-container
  article.view-article
  .article-tags
  .tag:hover
  .circle {
  background: #fff;
}

.article-container.article-view-container
  article.view-article
  .article-tags
  .tag:hover
  .desc {
  color: #fff;
}

.article-container.article-view-container.banner-image {
  background-attachment: fixed;
  background-color: #f0f0f0;
  background-position: center top;
  background-repeat: no-repeat;
  height: 375px;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .article-container.article-view-container.banner-image {
    width: 100%;
    height: 300px;
    background-color: #f0f0f0;
    background-position-y: 0 !important;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-attachment: scroll;
    position: relative;
  }

  .article-container.article-view-container article {
    padding: 0 20px;
  }

  .article-container.article-view-container article.view-article header .title {
    font-family: Montserrat, sans-serif;
    font-size: 1.5rem;
    font-weight: bolder;
    margin-bottom: 20px;
  }

  .article-container.article-view-container article.view-article .summary {
    font-size: 1rem;
    font-weight: 400;
  }

  .article-container.article-view-container
    article.view-article
    .author-details {
    margin-bottom: 0;
    text-align: center;
  }

  .article-container.article-view-container
    article.view-article
    .author-details
    .authorpic {
    position: relative;
  }

  .article-container.article-view-container
    article.view-article
    .author-details
    .big-circle-pic {
    margin: 0 auto;
    float: none;
    text-align: center;
  }

  .article-container.article-view-container
    article.view-article
    .author-details
    .details {
    padding-top: 0.5rem;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    max-width: 100%;
    height: inherit;
  }

  .article-container.article-view-container
    article.view-article
    .author-details
    .circle-hover-icons {
    margin: 0;
    position: relative;
    top: 1rem;
    left: 0;
    text-align: center;
  }

  .article-container.article-view-container .author-details .about-author {
    top: 0;
  }
}

@media screen and (max-width: 300px) {
  .article-container.article-view-container .author-bar {
    max-height: none !important;
  }
}

.article-container.article-view-container .author-details {
  background: #fff;
  box-shadow: 0 4px 5px -5px gray inset;
  margin-bottom: -130px;
  max-height: none;
  padding-bottom: 1pc;
  padding-bottom: 0.5rem;
  text-align: left;
}

.article-container.article-view-container .author-details .authorpic {
  padding: 0;
}

.article-container.article-view-container .author-details .big-circle-pic {
  background-color: #ededed;
  box-shadow: 0 0 2px 1px hsla(0, 0%, 50%, 0.49);
  border-radius: 5px;
  float: left;
  height: 150px;
  overflow: hidden;
  position: relative;
  top: 1pc;
  top: 0.5rem;
  width: 150px;
}

.article-container.article-view-container .author-details .big-circle-pic img {
  height: 100%;
  width: 100%;
}

.article-container.article-view-container .author-details .details {
  left: calc(150px + 0.5rem);
  max-width: calc(100% - 157px);
  position: relative;
  text-align: left;
  top: -162px;
  margin-left: 10px;
  height: 150px;
}

.article-container.article-view-container .author-details .author {
  color: #487cce;
  font-size: 2pc;
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 0;
  text-shadow: 0 0 #487cce;
}

.article-container.article-view-container .author-details .about-author {
  font-family: Montserrat, sans-serif;
  position: relative;
  text-shadow: 0 0.3px #000;
}

.article-container.article-view-container
  .author-details
  .about-author
  .content {
  font-size: 24px;
  font-size: 0.75rem;
  letter-spacing: 1px;
  line-height: 0.75em;
}
